import { Capacitor } from '@capacitor/core';
import { changeLanguage } from '@consolidate/shared/util-translations';
import * as Sentry from '@sentry/capacitor';
import {
  OidcClient,
  User,
  UserManager,
  WebStorageStateStore,
} from 'oidc-client';
import { capacitorNavigator } from './CapacitorNavigator';
import { ConsolidateUser } from './models/consolidateUser';

class AuthService {
  private manager?: UserManager;
  private clientId?: string;
  private scopes?: string[];

  private store = new WebStorageStateStore({});

  private _user: User | null = null;
  private get user(): User | null {
    return this._user;
  }
  private set user(val: User | null) {
    this._user = val;
    if (val) {
      changeLanguage(val.profile.locale ?? 'de');
    }
  }

  public get authority(): string | null {
    let url = localStorage.getItem('authority');

    if (url?.endsWith('/')) {
      url = url.substring(0, url.length - 1);
    }

    return url;
  }

  private setAuthority(val: string | null) {
    if (val) {
      localStorage.setItem('authority', val);
    } else {
      localStorage.removeItem('authority');
    }
  }

  public async init(clientId: string, scopes: string[]) {
    this.clientId = clientId;
    this.scopes = scopes;

    this.setManager(this.authority, scopes);

    if (this.manager) {
      await this.manager.clearStaleState();
      this.user = await this.manager.getUser();
    }
  }

  public async processLoginCallback(): Promise<User | undefined> {
    const user = await this.manager?.signinCallback(window.location.href);
    capacitorNavigator.close();

    return user;
  }

  public async login(webServiceUrl: string): Promise<void> {
    if (!this.clientId) throw new Error('AuthService not initialized');

    const supportedScopes = await this.getSupportedScopes(webServiceUrl);
    this.setManager(webServiceUrl, supportedScopes);

    await this.manager?.signinRedirect();
  }

  public async logout(): Promise<void> {
    if (!this.manager) throw new Error('AuthService not initialized');

    await this.manager.signoutRedirect();
    if (Capacitor.isNativePlatform()) {
      window.location.href = '../';
      capacitorNavigator.close();
    }
  }

  public getWebServiceUrl(): string | undefined {
    if (!this.manager) throw new Error('AuthService not initialized');

    return this.manager.settings.authority;
  }

  public isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  public getUser(): ConsolidateUser | null {
    if (!this.user?.profile) return null;

    return {
      uid: this.user.profile['uid'],
      firstName: this.user.profile['given_name'],
      lastName: this.user.profile['family_name'],
      locale: this.user.profile['locale'],
      ...this.user.profile,
    };
  }

  public getScopes(): string[] {
    return this.user?.scopes ?? [];
  }

  public getAccessToken(): string | null {
    if (!this.user?.access_token) return null;

    return `${this.user.token_type} ${this.user.access_token}`;
  }

  private refreshPromise: Promise<User> | null = null;
  public async refreshToken(): Promise<void> {
    if (!this.manager) throw new Error('AuthService not initialized');

    // don't call multiple token refreshes at the same time
    if (this.refreshPromise) {
      await this.refreshPromise;
      return;
    }

    try {
      // also refresh user info on refresh https://github.com/IdentityModel/oidc-client-js/issues/1034
      this.refreshPromise = this.manager.signinSilent().then(async (user) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const userInfo = (this.manager as any)._validator._userInfoService;

        const claims = await userInfo.getClaims(user.access_token);
        Object.assign(user.profile, claims);
        await this.manager?.storeUser(user);

        return user;
      });

      this.user = await this.refreshPromise;
    } catch (error) {
      console.error('error during token refresh', error);
      Sentry.captureException(error);
    }
    this.refreshPromise = null;
  }

  private get baseUrl() {
    if (Capacitor.isNativePlatform()) {
      return 'eu.consolidate.app:';
    }

    return window.location.origin;
  }

  private setManager(authority: string | null, scopes: string[]): void {
    if (!authority) return;
    this.setAuthority(authority);

    this.manager = new UserManager({
      userStore: this.store,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Authority has been set above
      authority: this.authority!,
      client_id: this.clientId,
      redirect_uri: this.baseUrl + '/login-callback',
      response_type: 'code',
      scope: scopes.join(' '),
      post_logout_redirect_uri: this.baseUrl + '/',
      extraQueryParams: {
        back_url: Capacitor.isNativePlatform() ? '' : this.baseUrl + '/login',
      },
      redirectNavigator: capacitorNavigator,
      monitorSession: false,
    });

    this.manager.events.addUserLoaded((user) => {
      this.user = user;
    });

    this.manager.events.addAccessTokenExpiring(() => {
      this.refreshToken();
    });

    this.manager.events.addUserUnloaded(() => {
      this.user = null;
    });
  }

  private async getSupportedScopes(authority: string) {
    const requestedScopes = this.scopes ?? [];

    if (!authority) return requestedScopes;

    const client = new OidcClient({
      authority: authority,
    });

    const metadata = await client.metadataService.getMetadata();
    const supportedScopes = requestedScopes.filter((x) =>
      metadata.scopes_supported.includes(x)
    );

    return supportedScopes;
  }
}

export default new AuthService();
