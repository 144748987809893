/* eslint-disable @typescript-eslint/no-var-requires */
import { VueConstructor } from 'vue/types/umd';
import {
  VuetifyIconComponent,
  VuetifyIcons,
} from 'vuetify/types/services/icons';
import CsIconBase from './CsIconBase/CsIconBase.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getIcon(icon: VueConstructor, styles?: any): VuetifyIconComponent {
  return {
    component: CsIconBase,
    props: {
      icon,
      styles,
    },
  };
}

export default {
  // Activity
  Activity1: getIcon(
    require('./streamline-regular/emails/envelopes/envelope.svg')
  ),
  Activity2: getIcon(
    require('./streamline-regular/emails/email-actions/email-action-read.svg')
  ),
  Activity3: getIcon(
    require('./streamline-regular/phones-mobile-devices/phone/phone.svg')
  ),
  Activity4: getIcon(
    require('./streamline-regular/phones-mobile-devices/fax-answer-machine/answer-machine.svg')
  ),
  Activity5: getIcon(
    require('./streamline-regular/shopping-ecommerce/products/products-purse-2.svg')
  ),
  Activity6: getIcon(
    require('./streamline-regular/interface-essential/alerts/information-circle.svg')
  ),
  Activity7: getIcon(
    require('./streamline-regular/interface-essential/date-calendar/calendar-3.svg')
  ),
  Activity8: getIcon(
    require('./streamline-regular/travel/planes-trip/plane-trip-1.svg')
  ),
  Activity9: getIcon(
    require('./streamline-regular/users/geometric-full-body-multiple-users/multiple-users-1.svg')
  ),
  Activity10: getIcon(
    require('./streamline-regular/programing-apps-websites/plugins-modules/module-puzzle.svg')
  ),
  Activity11: getIcon(
    require('./streamline-regular/interface-essential/form-validation/check-double.svg')
  ),
  Activity12: getIcon(
    require('./streamline-regular/interface-essential/time/time-clock-circle.svg')
  ),
  Activity13: getIcon(
    require('./streamline-regular/shipping-delivery/delivery/delivery-truck-4.svg')
  ),
  Activity14: getIcon(
    require('./streamline-regular/interface-essential/pin/pin.svg')
  ),
  Activity15: getIcon(
    require('./streamline-regular/work-office-companies/tasks/task-list-text.svg')
  ),
  Activity16: getIcon(
    require('./streamline-regular/interface-essential/alerts/alert-diamond.svg')
  ),
  Activity17: getIcon(
    require('./streamline-regular/work-office-companies/video-meetings/meeting-team-monitor-man-1.svg')
  ),
  Activity18: getIcon(
    require('./streamline-regular/tools-constructions/tools/tools-wench-screwdriver.svg')
  ),
  Activity19: getIcon(
    require('./streamline-regular/content/content-creation/content-notebook-pencil.svg')
  ),
  Activity20: getIcon(
    require('./streamline-regular/interface-essential/view/binocular.svg')
  ),
  Activity21: getIcon(
    require('./streamline-regular/money-payments-finance/finance-savings/saving-bank.svg')
  ),
  Activity22: getIcon(
    require('./streamline-regular/messages-chat-smileys/conversation/conversation-chat.svg')
  ),
  Activity23: getIcon(
    require('./streamline-regular/shopping-ecommerce/discount-coupons/discount.svg')
  ),
  Activity24: getIcon(
    require('./streamline-regular/content/content-creation/content-pen-3.svg')
  ),
  Activity25: getIcon(
    require('./streamline-regular/computers-devices-electronics/keyboard-mouse/mouse.svg')
  ),
  Activity26: getIcon(
    require('./streamline-regular/weather/rain/rain-umbrella.svg')
  ),
  Activity27: getIcon(
    require('./streamline-regular/money-payments-finance/finance-savings/saving-piggy-dollars.svg')
  ),
  Activity28: getIcon(
    require('./streamline-regular/money-payments-finance/credit-card-payments/credit-card-dollar-1.svg')
  ),
  Activity29: getIcon(
    require('./streamline-regular/health-beauty/bandages-casts/bandage.svg')
  ),
  Activity30: getIcon(
    require('./streamline-regular/interface-essential/share/megaphone.svg')
  ),
  Activity31: getIcon(
    require('./streamline-regular/video-movies-tv/vintage-tv/vintage-tv.svg')
  ),
  Activity32: getIcon(
    require('./streamline-regular/video-movies-tv/movies/movies-reel-1.svg')
  ),
  Activity33: getIcon(
    require('./streamline-regular/food-drinks/fruits/fruit-apple.svg')
  ),
  Activity34: getIcon(
    require('./streamline-regular/images-photography/accessories/photography-equipment-film.svg')
  ),
  Activity35: getIcon(
    require('./streamline-regular/science/science/science-molecule.svg')
  ),
  Activity36: getIcon(
    require('./streamline-regular/science/astronomy/astronomy-telescope.svg')
  ),
  Activity37: getIcon(
    require('./streamline-regular/interface-essential/alert/alarm-bell-ring.svg')
  ),
  Activity38: getIcon(
    require('./streamline-regular/avatars/geometric-men-people/people-man-graduate.svg')
  ),
  Activity39: getIcon(
    require('./streamline-regular/design/design-tools/design-tool-magnet.svg')
  ),
  Activity40: getIcon(
    require('./streamline-regular/music-audio/music-instruments/instrument-guitar.svg')
  ),
  Activity41: getIcon(
    require('./streamline-regular/business-products/optimization/optimization-rocket-growth.svg')
  ),
  Activity42: getIcon(
    require('./streamline-regular/science/labs-experiments/lab-flask-experiment.svg')
  ),
  Activity43: getIcon(
    require('./streamline-regular/weather/temperatures/temperature-thermometer.svg')
  ),
  Activity44: getIcon(
    require('./streamline-regular/social-medias-rewards-rating/love-it/love-it-search.svg')
  ),
  Activity45: getIcon(
    require('./streamline-regular/shopping-ecommerce/tags-codes/barcode-tag.svg')
  ),
  Activity46: getIcon(
    require('./streamline-regular/money-payments-finance/money/accounting-coins-bill.svg')
  ),
  Activity47: getIcon(
    require('./streamline-regular/social-medias-rewards-rating/gifts/gift-box.svg')
  ),
  Activity48: getIcon(
    require('./streamline-regular/shopping-ecommerce/tags-codes/barcode.svg')
  ),
  Activity49: getIcon(
    require('./streamline-regular/social-medias-rewards-rating/likes/like-1.svg')
  ),
  Activity50: getIcon(
    require('./streamline-regular/music-audio/microphone/microphone-karaoke.svg')
  ),

  // Contact
  Contact1: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-phone-book.svg')
  ),
  Contact2: getIcon(
    require('./streamline-regular/users/geometric-full-body-single-user-neutral/single-neutral-statics-1.svg')
  ),
  Contact3: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-id-card-double.svg')
  ),
  Contact4: getIcon(
    require('./streamline-regular/building-construction/buildings/building-2.svg')
  ),
  Contact5: getIcon(
    require('./streamline-regular/shipping-delivery/overseas-shipment/shipment-truck.svg')
  ),
  Contact6: getIcon(
    require('./streamline-regular/money-payments-finance/accounting-billing/accounting-calculator-1.svg')
  ),
  Contact7: getIcon(
    require('./streamline-regular/content/archives/archive-books.svg')
  ),
  Contact8: getIcon(
    require('./streamline-regular/travel/baggage/baggage-check-in-user.svg')
  ),
  Contact9: getIcon(
    require('./streamline-regular/internet-networks-servers/worldwide-web/network-users.svg')
  ),
  Contact10: getIcon(
    require('./streamline-regular/users/geometric-full-body-multiple-users-actions/multiple-actions-share-2.svg')
  ),

  GenderMan: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-profile-picture.svg'),
    {
      color: '#97CFD6',
    }
  ),
  GenderWoman: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-profile-picture.svg'),
    {
      color: '#E09B62',
    }
  ),
  GenderOther: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-profile-picture.svg'),
    {
      color: '#AF7E9E',
    }
  ),
  GenderNeutral: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-profile-picture.svg')
  ),
  Address: getIcon(
    require('./streamline-regular/building-construction/buildings/building-house.svg')
  ),
  User: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral.svg')
  ),
  UnknownEmailAddress: getIcon(
    require('./streamline-regular/emails/read-email/read-email-at.svg')
  ),
  ImageUpload: getIcon(
    require('./streamline-regular/images-photography/image-files/image-file-upload.svg'),
    {
      color: '#AF7E9E',
    }
  ),

  // Navigation
  BurgerMenu: getIcon(
    require('./streamline-regular/interface-essential/menu/navigation-menu.svg')
  ),
  Overview: getIcon(
    require('./streamline-regular/interface-essential/dashboard/layout-dashboard.svg')
  ),
  Notifications: getIcon(
    require('./streamline-regular/interface-essential/alert/alarm-bell.svg')
  ),
  ICCs: getIcon(
    require('./streamline-regular/messages-chat-smileys/conversation/conversation-chat-2.svg')
  ),
  EmailSent: getIcon(
    require('./streamline-regular/emails/email-actions/email-action-send-1.svg')
  ),
  Task: getIcon(
    require('./streamline-regular/work-office-companies/tasks/task-list-pen.svg')
  ),
  Activity: getIcon(
    require('./streamline-regular/work-office-companies/tasks/task-list-approve.svg')
  ),
  Team: getIcon(
    require('./streamline-regular/users/geometric-full-body-multiple-users/multiple-circle.svg')
  ),
  Search: getIcon(
    require('./streamline-regular/interface-essential/search/search.svg')
  ),
  Folder: getIcon(
    require('./streamline-regular/files-folders/folders/folder.svg')
  ),
  FolderDisable: getIcon(
    require('./streamline-regular/files-folders/folders/folder-disable.svg')
  ),
  RemoveFromFolder: getIcon(
    require('./streamline-regular/files-folders/folders/folder-remove.svg')
  ),
  Calendar: getIcon(
    require('./streamline-regular/interface-essential/date-calendar/calendar-date.svg')
  ),
  Contact: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-id-card-4.svg')
  ),
  Projects: getIcon(
    require('./streamline-regular/programing-apps-websites/plugins-modules/module-three.svg')
  ),
  Objects: getIcon(
    require('./streamline-regular/design/shapes/shape-triangle.svg')
  ),
  Settings: getIcon(
    require('./streamline-regular/interface-essential/settings/cog.svg')
  ),
  Queue: getIcon(
    require('./streamline-regular/interface-essential/synchronize/synchronize-arrows-warning.svg')
  ),
  Kanban: getIcon(require('./custom/kanbanboard.svg')),
  Logout: getIcon(
    require('./streamline-regular/interface-essential/login-logout/logout-1.svg')
  ),
  Avatar: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-focus.svg')
  ),
  Plus: getIcon(
    require('./streamline-regular/interface-essential/remove-add/add.svg')
  ),
  AddCircle: getIcon(
    require('./streamline-regular/interface-essential/remove-add/add-circle.svg')
  ),
  RemoveCircle: getIcon(
    require('./streamline-regular/interface-essential/remove-add/remove-circle.svg')
  ),

  // Interface
  Refresh: getIcon(
    require('./streamline-regular/interface-essential/synchronize/synchronize-arrows-1.svg')
  ),
  Filter: getIcon(
    require('./streamline-regular/interface-essential/filter/filter-1.svg')
  ),
  Telescope: getIcon(
    require('./streamline-regular/science/astronomy/astronomy-telescope-stars.svg')
  ),
  Close: getIcon(
    require('./streamline-regular/interface-essential/form-validation/close.svg')
  ),
  More: getIcon(
    require('./streamline-regular/interface-essential/lists/list-bullets-1.svg')
  ),
  Done: getIcon(require('./custom/check.svg')),
  Visible: getIcon(
    require('./streamline-regular/interface-essential/view/view-1.svg')
  ),
  Invisible: getIcon(
    require('./streamline-regular/interface-essential/view/view-off.svg')
  ),
  Edit: getIcon(
    require('./streamline-regular/interface-essential/edit/pencil.svg')
  ),
  ExtendedMenu: getIcon(
    require('./streamline-regular/interface-essential/menu/navigation-menu-vertical.svg')
  ),
  Back: getIcon(
    require('./streamline-regular/interface-essential/keyboard/keyboard-arrow-left.svg')
  ),
  Trash: getIcon(
    require('./streamline-regular/interface-essential/delete/bin-2-alternate.svg')
  ),
  Attachment: getIcon(
    require('./streamline-regular/interface-essential/link-unlink/attachment.svg')
  ),
  Forward: getIcon(
    require('./streamline-regular/emails/email-actions/email-action-reply.svg'),
    {
      transform: 'rotateY(180deg)',
    }
  ),
  Reply: getIcon(
    require('./streamline-regular/emails/email-actions/email-action-reply.svg')
  ),
  ReplyAll: getIcon(
    require('./streamline-regular/emails/email-actions/email-action-reply-all.svg')
  ),
  Favorite: getIcon(
    require('./streamline-regular/social-medias-rewards-rating/rating/rating-star-1.svg')
  ),
  ContinueWorkflow: getIcon(
    require('./streamline-regular/arrows-diagrams/diagrams/diagram-arrow-dash-right.svg')
  ),
  Alarm: getIcon(
    require('./streamline-regular/interface-essential/alert/alarm-clock.svg')
  ),
  Left: getIcon(
    require('./streamline-regular/arrows-diagrams/arrows/arrow-left-1.svg')
  ),
  Right: getIcon(
    require('./streamline-regular/arrows-diagrams/arrows/arrow-right-1.svg')
  ),
  Priority: getIcon(
    require('./streamline-regular/transportation/dashboard-symbols/car-dashboard-warning.svg'),
    {
      color: '#FC3F14',
    }
  ),
  Birthday: getIcon(
    require('./streamline-regular/food-drinks/cakes-candies/cake-birthday.svg')
  ),
  Anniversary: getIcon(
    require('./streamline-regular/social-medias-rewards-rating/rewards-awards/award-medal-1.svg')
  ),
  Holiday: getIcon(
    require('./streamline-regular/entertainment-events-hobbies/party/party-balloons.svg')
  ),
  CreateAppointment: getIcon(
    require('./streamline-regular/interface-essential/date-calendar/calendar-add-1.svg')
  ),
  CreateAddress: getIcon(
    require('./streamline-regular/real-estate/actions-houses/real-estate-action-house-add.svg')
  ),
  CreateContact: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-actions-neutral/single-neutral-actions-add.svg')
  ),
  CreateDocument: getIcon(
    require('./streamline-regular/files-folders/common-files/common-file-text-add.svg')
  ),
  CreateEmail: getIcon(
    require('./streamline-regular/emails/email-actions/email-action-add.svg')
  ),
  Phone: getIcon(
    require('./streamline-regular/phones-mobile-devices/phone/phone.svg')
  ),
  Phone2: getIcon(
    require('./streamline-regular/phones-mobile-devices/fax-answer-machine/answer-machine-paper.svg')
  ),
  MobilePhone: getIcon(
    require('./streamline-regular/phones-mobile-devices/mobile-phones/mobile-phone.svg')
  ),
  Email: getIcon(
    require('./streamline-regular/emails/email-actions/email-action-unread.svg')
  ),
  Location: getIcon(
    require('./streamline-regular/maps-navigation/maps/maps-pin.svg')
  ),
  Download: getIcon(
    require('./streamline-regular/internet-networks-servers/upload-download/download-button.svg')
  ),
  Delegate: getIcon(
    require('./streamline-regular/business-products/business-deals/business-contract-give.svg')
  ),
  Lock: getIcon(
    require('./streamline-regular/interface-essential/lock-unlock/key-lock-1.svg')
  ),
  Reset: getIcon(
    require('./streamline-regular/interface-essential/synchronize/synchronize-arrow-2.svg')
  ),
  File: getIcon(
    require('./streamline-regular/files-folders/common-files/common-file-text.svg')
  ),
  ScanQrCode: getIcon(
    require('./streamline-regular/phones-mobile-devices/qr-code/qr-code-scan.svg')
  ),
  Expand: getIcon(
    require('./streamline-regular/arrows-diagrams/arrows/arrow-down-1.svg')
  ),
  Collapse: getIcon(
    require('./streamline-regular/arrows-diagrams/arrows/arrow-down-1.svg'),
    {
      transform: 'rotate(180deg)',
    }
  ),
  PickerDate: getIcon(
    require('./streamline-regular/interface-essential/date-calendar/calendar.svg')
  ),
  PickerTime: getIcon(
    require('./streamline-regular/interface-essential/time/time-clock-circle-1.svg')
  ),
  Comment: getIcon(
    require('./streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-quotation.svg')
  ),
  Print: getIcon(
    require('./streamline-regular/interface-essential/print/print-text.svg')
  ),
  PreviousActivity: getIcon(
    require('./streamline-regular/arrows-diagrams/diagrams/diagram-fall-fast.svg'),
    {
      transform: 'rotate(180deg)',
    }
  ),
  FollowUp: getIcon(
    require('./streamline-regular/arrows-diagrams/diagrams/diagram-fall-fast.svg')
  ),
  ConfirmationPending: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-actions-neutral/single-neutral-actions-question.svg')
  ),
  ConfirmationFixed: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-actions-neutral/single-neutral-actions-check-2.svg'),
    {
      color: '#538E58',
    }
  ),
  ConfirmationConfirmed: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-actions-neutral/single-neutral-actions-check-1.svg'),
    {
      color: '#538E58',
    }
  ),
  ConfirmationDeclined: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-actions-neutral/single-neutral-actions-remove.svg'),
    {
      color: '#FC3F14',
    }
  ),
  ConfirmationTentative: getIcon(
    require('./streamline-regular/users/geomertic-close-up-single-user-actions-neutral/single-neutral-actions-question.svg'),
    {
      color: '#538E58',
    }
  ),
  Hash: getIcon(
    require('./streamline-regular/interface-essential/text-formating/hash.svg')
  ),
  CarDashboardWarning: getIcon(
    require('./streamline-regular/transportation/dashboard-symbols/car-dashboard-warning.svg')
  ),
  CalendarClock: getIcon(
    require('./streamline-regular/interface-essential/date-calendar/calendar-clock.svg')
  ),
  MultipleActionsLock: getIcon(
    require('./streamline-regular/users/geomertic-close-up-multiple-users-actions/multiple-actions-lock.svg')
  ),
  ModuleThree: getIcon(
    require('./streamline-regular/programing-apps-websites/plugins-modules/module-three.svg')
  ),
  Save: getIcon(
    require('./streamline-regular/computers-devices-electronics/floppy-disks/floppy-disk.svg')
  ),
  Help: getIcon(
    require('./streamline-regular/interface-essential/alerts/question-circle.svg')
  ),
  Repetitive: getIcon(
    require('./streamline-regular/arrows-diagrams/diagrams/diagram-round.svg'),
    {
      transform: 'rotate(180deg)',
    }
  ),
  Web: getIcon(
    require('./streamline-regular/internet-networks-servers/worldwide-web/network-arrow.svg')
  ),
  Private: getIcon(
    require('./streamline-regular/romance/love/love-heart-hands-hold-1.svg')
  ),
  Department: getIcon(
    require('./streamline-regular/work-office-companies/meetings-collaboration/team-meeting.svg')
  ),
  Chain: getIcon(
    require('./streamline-regular/interface-essential/link-unlink/hyperlink-circle.svg')
  ),

  // Project
  Project1: getIcon(
    require('./streamline-regular/interface-essential/time/hourglass.svg')
  ),
  Project2: getIcon(
    require('./streamline-regular/design/design-tools/design-tool-compass.svg')
  ),
  Project3: getIcon(
    require('./streamline-regular/design/shapes/shape-triangle-circle.svg')
  ),
  Project4: getIcon(
    require('./streamline-regular/design/colors-painting/color-brush-paint-1.svg')
  ),
  Project5: getIcon(
    require('./streamline-regular/business-products/performances/performance-presentation-graph.svg')
  ),

  // Objects
  Object1: getIcon(
    require('./streamline-regular/building-construction/houses/house-3.svg')
  ),
  Object2: getIcon(require('./streamline-regular/transportation/cars/car.svg')),
  Object3: getIcon(
    require('./streamline-regular/tools-constructions/tools/tools-wench.svg')
  ),
  Object4: getIcon(
    require('./streamline-regular/interface-essential/alerts/radioactive-circle.svg')
  ),
  Object5: getIcon(
    require('./streamline-regular/interface-essential/alerts/alert-triangle.svg')
  ),

  // Folder
  Folder1: getIcon(
    require('./streamline-regular/files-folders/folders/folder-empty.svg'),
    {
      color: '#E09B62',
    }
  ),
  Folder2: getIcon(
    require('./streamline-regular/files-folders/folders/folder-empty.svg'),
    {
      color: '#7FBA9A',
    }
  ),
  Folder3: getIcon(
    require('./streamline-regular/files-folders/folders/folder-empty.svg'),
    {
      color: '#E8B558',
    }
  ),
  Folder4: getIcon(
    require('./streamline-regular/files-folders/folders/folder-empty.svg'),
    {
      color: '#AF7E9E',
    }
  ),
  Folder5: getIcon(
    require('./streamline-regular/files-folders/folders/folder-empty.svg'),
    {
      color: '#8797CE',
    }
  ),
  Folder6: getIcon(
    require('./streamline-regular/files-folders/folders/folder.svg'),
    {
      color: '#E09B62',
    }
  ),
  Folder7: getIcon(
    require('./streamline-regular/files-folders/folders/folder.svg'),
    {
      color: '#7FBA9A',
    }
  ),
  Folder8: getIcon(
    require('./streamline-regular/files-folders/folders/folder.svg'),
    {
      color: '#E8B558',
    }
  ),
  Folder9: getIcon(
    require('./streamline-regular/files-folders/folders/folder.svg'),
    {
      color: '#AF7E9E',
    }
  ),
  Folder10: getIcon(
    require('./streamline-regular/files-folders/folders/folder.svg'),
    {
      color: '#8797CE',
    }
  ),

  // Vuetify
  checkboxOff: getIcon(
    require('./streamline-regular/maps-navigation/sign-shapes/sign-badge-circle.svg'),
    {
      width: 20,
    }
  ),
  checkboxOn: getIcon(require('./custom/checkboxOn.svg'), {
    width: 20,
  }),

  radioOff: getIcon(
    require('./streamline-regular/maps-navigation/sign-shapes/sign-badge-circle.svg'),
    {
      width: 20,
    }
  ),
  radioOn: getIcon(require('./custom/radioOn.svg'), {
    width: 20,
  }),

  dropdown: getIcon(
    require('./streamline-regular/arrows-diagrams/arrows/arrow-down-1.svg'),
    {
      width: 12,
    }
  ),
  delete: getIcon(
    require('./streamline-regular/interface-essential/delete/delete-1.svg')
  ),
  close: getIcon(
    require('./streamline-regular/interface-essential/form-validation/close.svg'),
    {
      width: 16,
    }
  ),
  search: getIcon(
    require('./streamline-regular/interface-essential/search/search.svg'),
    {
      width: 16,
    }
  ),
  AgendaView: getIcon(
    require('./streamline-regular/interface-essential/menu/navigation-menu-4.svg')
  ),
  DayView: getIcon(require('./custom/layout-top-adjusted.svg')),
  SeveralDaysView: getIcon(
    require('./streamline-regular/interface-essential/layouts/layout-3.svg')
  ),
  MonthView: getIcon(
    require('./streamline-regular/programing-apps-websites/apps (2)/app-window-layout.svg')
  ),
  SwitchView: getIcon(
    require('./streamline-regular/programing-apps-websites/apps (2)/app-window-two.svg')
  ),
} as Partial<VuetifyIcons>;
