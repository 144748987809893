import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import { VueConstructor } from 'vue/types/umd';
import Vuetify from 'vuetify/lib';
import icons from '../icons/icons';

const theme = {
  primary: '#005598',
  secondary: '#8c8c8c',
  accent: '#E68F69',

  info: '#9AC1E1',
  success: '#538E58',
  warning: '#FF8900',
  error: '#FC3F14',

  lightgrey: '#c8c8c1',
};

export const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        ...theme,
        surface: '#f5f2e9',
      },
      dark: {
        ...theme,
        surface: '#484646',
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    values: icons,
  },
});

export const install = (Vue: VueConstructor): void => {
  // Add vuetify here vie mixin so we don't have to include
  // it in the options when creating the vue instance
  Vue.mixin({
    beforeCreate() {
      if (!this.$options.vuetify && !this.$parent) {
        this.$options.vuetify = vuetify;
      }
    },
  });

  Vue.use(VueTelInputVuetify, { vuetify });

  Vue.use(Vuetify);
};
